import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Header, Footer, Stars } from './components';
import Home from './pages/Home';
import Company from './pages/Company';
import Contact from './pages/Contact';
import Resources from './pages/Resources';
import NavigatingLocalLaw97 from './pages/Blog/NavigatingLocalLaw97';
import WhatIsLocalLaw97 from './pages/Blog/WhatIsLocalLaw97';
import LocalLaw87 from './pages/Blog/LocalLaw87';

const App = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <Stars />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/company" element={<Company />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/blog/navigating-nyc-local-law-97" element={<NavigatingLocalLaw97 />} />
            <Route path="/blog/what-is-local-law-97-in-nyc" element={<WhatIsLocalLaw97 />} />
            <Route path="/blog/local-law-87-new-york" element={<LocalLaw87 />} />

          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;