import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const BlogCard = ({ title, description, image, pageLink }) => (
  <Link to={pageLink}>
    <div className="bg-[#2a2a3e] rounded-lg overflow-hidden py-4 my-4 w-[800px]">
      <img src={image} alt={title} className="w-full h-48 object-cover" />
      <div className="p-4">
        <h3 className="text-white text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-400 text-sm">{description}</p>
      </div>
    </div>
  </Link>
);

const BlogCaseStudiesCarousel = () => {  
  const posts = [
    {
      title: "Navigating NYC’s Local Law 97: A practical guide for property managers",
      description: "Read on for step-by-step strategies to help hit emissions goals, avoid fines, and automate compliance. To figure out your building’s emissions, start by checking your energy bills. Document how much electricity and fuel your building used last year.",
      image: "/images/blog/blog-cover2.webp",
      pageLink: "/blog/navigating-nyc-local-law-97",
    },
    {
      title: "What is Local Law 97 in New York City?",
      description: "What property managers need to understand, what happens if you don’t comply, and how to meet the upcoming deadlines. Two-thirds of New York City’s greenhouse gas emissions come from its buildings. Local Law 97 is the city’s ambitious response, aiming for net-zero emissions by 2050.",
      image: "/images/blog/blog-cover.webp",
      pageLink: "/blog/what-is-local-law-97-in-nyc",
    },
    {
      title: "Local Law 87 Compliance Guide",
      description: "A helpful guide on what is required to stay compliant with Local Law 87 in New York. With a bit of strategic planning and the right tools, preparing energy efficiency standards for New York City’s Local Law 87 compliance becomes an opportunity to make your building more efficient.",
      image: "/images/blog/blog-cover2.webp",
      pageLink: "/blog/local-law-87-new-york",
    },
  ];

  return (
    <section className="text-white py-20">
      <div className="container mx-auto px-4">
        <div className="text-[#7c3aed] text-lg mb-2 text-center">Blog & Case Studies</div>
        <h2 className="text-4xl font-bold mb-4 text-center max-w-3xl mx-auto">
          Discover insights and real-world success stories from Cosmos
        </h2>
        <p className="text-gray-400 text-center mb-12 max-w-2xl mx-auto">
          Stay updated with industry trends, news, and energy-saving
          tips from our expert team
        </p>
        <div className="flex flex-col items-center">
          {posts.map((post, index) => (
            <BlogCard key={index} {...post} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogCaseStudiesCarousel;