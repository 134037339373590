import React from 'react';
import { BlogCaseStudiesCarousel, CosmosInfoBanner, Hero, Features, Stats, RequestDemo } from '../components';

const Home = () => {
  return (
    <div className="home">
      <Hero />
      <CosmosInfoBanner />
      <Stats />
      <Features />
      <BlogCaseStudiesCarousel />
      <RequestDemo />
    </div>
  );
};

export default Home;