import React from 'react';
import { motion } from 'framer-motion';

const LocalLaw87 = () => {
  return (
    <section className="bg-cosmos-dark text-cosmos-dark py-16 md:py-24">
      <div className="container mx-auto px-4 max-w-4xl bg-[#F8E9E9] rounded-lg p-4">
        <motion.h1 
          className="text-4xl md:text-5xl font-bold my-8 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Local Law 87 Compliance Guide
          <br />
          <br />
          How to prepare for NYC’s energy efficiency standards
        </motion.h1>

        <motion.h2
          className="text-xl font-bold mb-8 text-center"
        >
          With a bit of strategic planning and the right tools, preparing energy efficiency standards for New York City’s Local Law 87 compliance becomes an opportunity to make your building more efficient.
        </motion.h2>

        <img src="/images/blog/blog-cover2.webp" alt="Navigating NYC Local Law 97" className="w-full h-48 object-cover" />

        <motion.h2 className="text-xl font-bold mt-8 mb-4">
          Requirements
        </motion.h2>

        <motion.p>
          For any buildings over 50,000 square feet, you must:
          <br />
          <br />
          1. Conduct an energy audit
          <br />
          2. Perform retro-commissioning
          <br />
          3. Submit an Energy Efficiency Report (EER) to the Department of Buildings (DOB) that includes findings from both the audit and the retro-commissioning assessment
          <br />
          <br />
          The energy audit serves as your baseline, pinpointing opportunities to reduce energy use after a team of certified professionals examine all parts of a building’s energy usage.
          For specific guidelines and an auditing template, check out the DOB’s <a href='https://www.nyc.gov/site/buildings/codes/ll87-energy-audits-retro-commissioning.page' className='text-cosmos-purple'>resource page</a>.
        </motion.p>

        <motion.h2 className="text-xl font-bold mt-8 mb-4">
          Energy Audits
        </motion.h2>

        <motion.p>
          Energy audits are essential for uncovering inefficiencies that drive up operational costs.
          By identifying areas where energy is being wasted, audits offer actionable insights that help property owners lower expenses and maximize performance.
          <br />
          <br />
          Conduct energy audits to:
          <br />
          <br />
          - Establish baseline energy consumption for your building
          <br />
          - Identify opportunities for energy reduction
          <br />
          <br />
          Energy audits must be performed by certified professionals and are required every 10 years.
          <br />
          Check out the DOB’s <a href='https://www.nyc.gov/site/buildings/codes/ll87-energy-audits-retro-commissioning.page' className='text-cosmos-purple'>resource page</a> for a full list of approved auditors and guidelines.
        </motion.p>

        <motion.h2 className="text-xl font-bold mt-8 mb-4">
          Retro-Commissioning
        </motion.h2>

        <motion.p>
          The purpose of retro-commissioning is to get your building systems in sync and up to date.
          <br />
          This process optimizes building systems through:
          <br />
          <br />
          - System maintenance and repairs
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;- Clean HVAC components
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;- Replace filters
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;- Inspect pumps, fans, and insulation

          <br />
          <br />

          - Performance optimization
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;- Eliminate simultaneous heating/cooling
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;- Optimize boiler settings
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;- Adjust lighting controls
        </motion.p>

        <motion.h2 className="text-xl font-bold mt-8 mb-4">
          Compliance Challenges
        </motion.h2>

        <motion.p>
          - Resource-intensive data collection and reporting
          <br />
          - Requires specialized expertise
          <br />
          - Non-compliance results in significant fines
        </motion.p>

        <motion.h2 className="text-xl font-bold mt-8 mb-4">
          AI-Assisted Compliance Solutions
        </motion.h2>

        <motion.p>
          Modern tools can help by:
          <br />
          <br />
          - Automating data collection and analysis
          <br />
          - Providing predictive maintenance alerts
          <br />
          - Monitoring system performance in real-time
          <br />
          - Reducing operational costs
          <br />
          - Preparing for future regulations (like Local Law 97)
        </motion.p>

        <motion.h2 className="text-xl font-bold mt-8 mb-4">
          Important Notes
        </motion.h2>

        <motion.p>
          - Compliance operates on a 10-year cycle
          <br />
          - LL87 focuses on energy use optimization
          <br />
          - LL97 addresses emission reductions
          <br />
          - For specific guidelines and templates, visit the DOB resource page
          <br />
          - Consult qualified professionals for legal compliance guidance
        </motion.p>

        <motion.p className="text-lg my-8">
          Looking for help with you Energy Efficiency Report? Read about how <a href='https://golivecosmos.com' className='text-cosmos-purple'>Cosmos</a> can help automate your data collection and analysis to stay compliant.
        </motion.p>
      </div>
    </section>
  );
};

export default LocalLaw87;