import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { BlogCaseStudiesCarousel } from '../components';

const Resources = () => {
  return (
    <section className="bg-cosmos-dark text-white py-16 md:py-24">
      <div className="container mx-auto px-4 max-w-3xl">
        <motion.h1 
          className="text-4xl md:text-5xl font-bold mb-8 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Get in Touch
        </motion.h1>
        
        <BlogCaseStudiesCarousel />

      </div>
    </section>
  );
};

export default Resources;