import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleFeaturesClick = (event) => {
    event.preventDefault();
    if (location.pathname === '/') {
      const featuresSection = document.getElementById('features');
      if (featuresSection) {
        featuresSection.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate('/#features');
    }
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    const handleClickOutside = (event) => {
      if (isMenuOpen && menuRef.current && !menuRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  const closeMenu = () => setIsMenuOpen(false);

  const menuVariants = {
    open: { 
      x: 0,
      transition: { 
        type: "spring", 
        stiffness: 300, 
        damping: 30 
      }
    },
    closed: { 
      x: "100%",
      transition: { 
        type: "spring", 
        stiffness: 300, 
        damping: 30 
      }
    }
  };

  const menuItemVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };

  return (
    <motion.header 
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-gray-900/90 backdrop-blur-md py-2' : 'bg-transparent py-4'}`}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
    >
      <div className="container mx-auto flex justify-between items-center px-4">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Link to="/" className="flex items-center">
            <img 
              src={`/cosmos-logo-purple.svg`}
              alt={`Logo`}
              className="w-32 md:w-40 h-auto object-contain"
            />
          </Link>
        </motion.div>
        <AnimatePresence>
          {isMenuOpen && (
            <motion.nav
              ref={menuRef}
              className="fixed top-0 right-0 bottom-0 flex flex-col justify-center bg-gray-900/95 backdrop-blur-md w-64 p-8 md:hidden"
              variants={menuVariants}
              initial="closed"
              animate="open"
              exit="closed"
            >
              <ul className="flex flex-col space-y-6 text-lg">
                <motion.li variants={menuItemVariants}><Link to="/company" className="text-gray-300 hover:text-[#7c3aed] transition-colors duration-200" onClick={closeMenu}>Company</Link></motion.li>
                <motion.li variants={menuItemVariants}><a href="#features" onClick={handleFeaturesClick} className="text-gray-300 hover:text-[#7c3aed] transition-colors duration-200">Features</a></motion.li>
                <motion.li variants={menuItemVariants}><Link to="/contact" className="text-gray-300 hover:text-[#7c3aed] transition-colors duration-200" onClick={closeMenu}>Contact Us</Link></motion.li>
              </ul>
              <motion.div variants={menuItemVariants} className="mt-6">
                <Link to="https://app.golivecosmos.com" className="block text-white hover:text-[#7c3aed] mb-4 transition-colors duration-200" onClick={closeMenu}>Sign In</Link>
                <Link to="https://calendly.com/brian-correa-cosmos/30-minute-call" className="block bg-[#7c3aed] text-white px-4 py-2 rounded-full hover:bg-[#6d28d9] transition-colors duration-200 text-center" onClick={closeMenu}>Request a Demo</Link>
              </motion.div>
            </motion.nav>
          )}
        </AnimatePresence>
        <motion.nav className="hidden md:flex space-x-6">
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
            <Link to="/company" className="text-gray-300 hover:text-[#7c3aed] transition-colors duration-200">Company</Link>
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
            <a href="#features" onClick={handleFeaturesClick} className="text-gray-300 hover:text-[#7c3aed] transition-colors duration-200">Features</a>
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
            <Link to="/contact" className="text-gray-300 hover:text-[#7c3aed] transition-colors duration-200">Contact Us</Link>
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
            <Link to="/resources" className="text-gray-300 hover:text-[#7c3aed] transition-colors duration-200">Resources</Link>
          </motion.div>
        </motion.nav>
        <motion.div className="hidden md:flex space-x-4 items-center">
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
            <Link to="https://app.golivecosmos.com" className="text-white hover:text-[#7c3aed] transition-colors duration-200">Sign In</Link>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Link to="https://calendly.com/brian-correa-cosmos/30-minute-call" className="bg-[#7c3aed] text-white px-4 py-2 rounded-full hover:bg-[#6d28d9] transition-colors duration-200">Request a Demo</Link>
          </motion.div>
        </motion.div>
        <motion.button 
          ref={buttonRef}
          className="md:hidden text-white focus:outline-none"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            {isMenuOpen ? (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            ) : (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            )}
          </svg>
        </motion.button>
      </div>
    </motion.header>
  );
};

export default Header;