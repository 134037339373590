import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const CosmosInfoBanner = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  };

  return (
    <motion.section
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="text-white py-24 overflow-hidden relative"
    >
      <div className="container mx-auto text-center px-4 relative z-10">
        <motion.h2
          variants={itemVariants}
          className="text-4xl font-bold mb-6 bg-gradient-to-r from-[#7c3aed] to-[#3b82f6] bg-clip-text text-transparent"
        >
          Simplifying Building Decarbonization Compliance
        </motion.h2>
        <motion.p
          variants={itemVariants}
          className="text-xl mb-16 max-w-3xl mx-auto font-medium leading-relaxed text-gray-300 text-left"
        >
          Energy benchmarking laws in the United States are growing 40% year over year.
          <br />
          These regulations require annual Greenhouse Gas (GHG) emissions reporting for over 100,000 buildings.
          <br />
          <br />
          Cosmos navigates the complex world of building decarbonization laws for you.
          <br />
          With our platform, you can track emissions, uncover reduction opportunities, and stay ahead of the latest regulations.
        </motion.p>
      </div>
    </motion.section>
  );
};

const styles = `
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-scroll {
  animation: scroll 30s linear infinite;
}
`;

export default () => (
  <>
    <style>{styles}</style>
    <CosmosInfoBanner />
  </>
);