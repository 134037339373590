import React from 'react';
import { motion } from 'framer-motion';

const NavigatingLocalLaw97 = () => {
  return (
    <section className="bg-cosmos-dark text-cosmos-dark py-16 md:py-24">
      <div className="container mx-auto px-4 max-w-4xl bg-[#F8E9E9] rounded-lg p-4">
        <motion.h1 
          className="text-4xl md:text-5xl font-bold my-8 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Navigating NYC’s Local Law 97:
          <br />
          <br />
          A guide for property managers
        </motion.h1>

        <motion.h2
          className="text-xl font-bold mb-8 text-center"
        >
          Read on for step-by-step strategies to help hit emissions goals, avoid fines, and automate compliance.
        </motion.h2>

        <img src="/images/blog/blog-cover2.webp" alt="Navigating NYC Local Law 97" className="w-full h-48 object-cover" />

        <motion.h3 className="text-md font-bold my-8">
          Step 1: Understand your building’s emissions baseline.
        </motion.h3>

        <motion.p>
          To figure out your building’s emissions, start by checking your energy bills. Document how much electricity and fuel your building used last year. 
          <br />
          <br />
          Next, use a calculator or another tool that turns those numbers into a carbon emissions total. You can use a tool like the Arcadis CLIMATESCOUT ™ calculator to help with this.
          <br />
          <br />
          Tip: Many buildings over 25,000 square feet are already required to track energy usage, but double-check your records to verify accuracy.
        </motion.p>

        <motion.h3 className="text-md font-bold my-8">
          Step 2: Set your reduction targets.
        </motion.h3>

        <motion.p>
          Getting your building's emissions baseline gives you a clear starting point for meeting Local Law 97 requirements. With emissions limits taking effect in 2025—and tighter ones in 2030—knowing your baseline helps you see how much of your emissions  you need to cut to avoid penalties.
          <br />
          <br />
          Why it matters: Exceeding emissions limits could cost up to $268 per metric ton of carbon dioxide equivalent annually. Additional penalties and fees may apply, depending on the nature of noncompliance.  
        </motion.p>

        <motion.h3 className="text-md font-bold my-8">
          Step 3: Develop an emissions reduction plan.
        </motion.h3>

        <motion.p>
          To stay compliant, you’ll need a solid decarbonization strategy to cut emissions. You can start by retrofitting systems like HVAC and lighting for higher efficiency. These upgrades can lead to significant long-term savings on energy bills despite the upfront costs.
          <br />
          <br />
          A decarbonization plan is also essential. Many property managers create comprehensive plans with energy audits and clear steps for emissions reduction. This can show “good faith efforts” when filing reports with the NYC’s Department of Buildings (DOB).
          <br />
          <br />
          Decarbonization tip: Focus on upgrades that offer both compliance and cost savings over time.
        </motion.p>
        
        <motion.h3 className="text-md font-bold my-8">
          Step 4: Automate compliance.
        </motion.h3>

        <motion.p>
          If you are managing multiple properties, consider automating your compliance.
          <br />
          <br />
          A platform like Cosmos can help by letting building managers track emissions, uncover opportunities for reduction, and predict potential penalties. Cosmos can automate utility bill uploads and benchmark utility usage, which helps you measure your emissions and submit emissions reports without manual work.
        </motion.p>

        <motion.h3 className="text-md font-bold my-8">
          Step 5: Regularly monitor and adjust you emission goals.
        </motion.h3>

        <motion.p>
          Staying compliant with Local Law 97 requires planning ahead. Track your building’s emissions each year and make adjustments as needed to stay within limits and avoid fines.
        </motion.p>

        <motion.h3 className="text-md font-bold my-8">
          Step 6: File your emissions reports on time. 
        </motion.h3>

        <motion.p>
          Starting in 2025, property managers must submit annual emissions reports to the NYC DOB to stay compliant and avoid penalties. 
          <br />
          <br />
          Using a tool like Cosmos can simplify reporting by automatically generating emissions reports from your building’s utility bills. This not only reduces the risk of errors but also saves you time.
        </motion.p>


        <motion.h2 className="text-lg my-8 text-center">
          FAQs on Local Law 97 compliance and emissions reduction
        </motion.h2>

        <motion.h3 className="text-md font-bold my-8">
          How can I set emissions reduction targets for Local Law 97 compliance?
        </motion.h3>

        <motion.p>
          Instead of aiming for a large reduction all at once, set yearly goals. By reducing emissions by a specific percentage each year, you can track progress and make adjustments as needed, keeping the overall goal more achievable.
        </motion.p>

        <motion.h3 className="text-md font-bold my-8">
           What’s the penalty for non-compliance with Local Law 97?
        </motion.h3>

        <motion.p>
          $268 fine per metric ton of carbon dioxide above the allowed limit each year.
        </motion.p>

      </div>
    </section>
  );
};

export default NavigatingLocalLaw97;