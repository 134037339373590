import React from 'react';
import { motion } from 'framer-motion';

const WhatIsLocalLaw97 = () => {
  return (
    <section className="bg-cosmos-dark text-cosmos-dark py-16 md:py-24">
      <div className="container mx-auto px-4 max-w-4xl bg-[#F8E9E9] rounded-lg p-4">
        <motion.h1 
          className="text-4xl md:text-5xl font-bold my-8 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          What is Local Law 97 in New York City?
        </motion.h1>

        <motion.h2 className="text-xl font-bold mb-8 text-center">
          What property managers need to understand, what happens if you don’t comply, and how to meet the upcoming deadlines.
        </motion.h2>

        <img src="/images/blog/blog-cover.webp" alt="What is Local Law 97 in NYC" className="w-full h-48 object-cover" />

        <motion.p className='mt-8'>
          Two-thirds of New York City’s greenhouse gas emissions come from its buildings. Local Law 97 is the city’s ambitious response, aiming for net-zero emissions by 2050. 
          <br />
          <br />
          As of fall 2024, roughly 80% of NYC buildings comply with the law’s projected standards. However, maintaining that status requires ongoing effort. Stricter limits are coming in 2030, and preparation is key to staying ahead of this legislation.
          <br />
          <br />
          The first major deadline for property managers arrives in May 2025.
        </motion.p>

        <motion.h3 className="text-md font-bold my-8">
        What is Local Law 97 in New York City?
        </motion.h3>

        <motion.p>
          Local Law 97, passed in 2019 as part of the NYC Green New Deal, aims to cut greenhouse gas emissions from large buildings (with some exceptions). The first major milestone is to reduce carbon output by 40% from each building’s 2005 levels by 2030. Then, by 2050, the goal is for all large, nonexempt buildings to achieve carbon neutrality.
          <br />
          <br />
          LL97 affects most buildings larger than 25,000 square feet, requiring property owners to curb their environmental impact and improve energy efficiency.
          The law applies to:
          <br />
          <br />
          - Buildings over 25,000 square feet
          <br />
          - Two or more buildings on the same tax lot exceeding 50,000 square feet
          <br />
          - Condo buildings managed by the same board exceeding 50,000 square feet
          <br />
          <br />
          For full details on which properties are covered and any exemptions, read the <a className='font-bold' href='https://www.nyc.gov/assets/buildings/local_laws/ll97of2019.pdf'>law from NYC Department of Buildings</a>.
        </motion.p>

        <motion.h3 className="text-md font-bold my-8">
          Why does Local Law 97 matter?
        </motion.h3>

        <motion.p>
          LL97 is a landmark piece of legislation with significant implications for property managers and owners. As one of the most progressive climate laws in the nation, it’s establishing a new standard for urban sustainability efforts. Buildings that fail to meet emissions targets will also face penalties.
          <br />
          <br />
          Property managers must be prepared to manage the compliance process, including:
          <br />
          - Identifying whether your building qualifies under the law.
          <br />
          - Developing strategies to meet emissions targets, like upgrading HVAC systems or investing in Energy Conservation Measures.
          <br />
          - Understanding the law's requirements to avoid potentially high fines.
        </motion.p>
        
        <motion.h3 className="text-md font-bold my-8">
          Consequences for noncompliance with Local Law 97
        </motion.h3>

        <motion.p>
          Property owners will be fined $268 per metric ton of carbon dioxide by which their building exceeds its emissions limits.
          <br />
          <br />
          For example, if a building exceeds its emissions limit by 1,000 tons of carbon dioxide emissions, it will face an annual fine of $268,000.
        </motion.p>

        <motion.h3 className="text-md font-bold my-8">
          How to stay compliant with Local Law 97
        </motion.h3>

        <motion.p>
          Staying compliant with LL97 is possible with preparation and planning. Here’s how property managers can keep up:
          <br />
          <br />
          1. Submit annual emissions reports. Emissions reports are required by NYC Department of Buildings starting on May 1, 2025. These reports must accurately reflect the building's emissions to avoid penalties.
          <br />
          <br />
          2. Develop a decarbonization plan. One of the best ways to demonstrate a "good faith effort" toward compliance is by submitting a plan outlining how you’ll reduce on-site emissions by 2026. This plan must be certified by a registered design professional and backed by an energy audit no more than four years old.
          <br />
          <br />
          3. Submit proof of progress. By May 1, 2028, you must provide evidence that the NYC Department of Buildings has approved your application for the work needed to meet the 2030 emissions limits.
          <br />
          <br />
        </motion.p>

      </div>
    </section>
  );
};

export default WhatIsLocalLaw97;