import React from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const StatItem = ({ value, label, index }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="text-center p-6 bg-gray-800 rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl hover:bg-gray-750"
    >
      <motion.div
        initial={{ scale: 0.5 }}
        animate={inView ? { scale: 1 } : {}}
        transition={{ type: "spring", stiffness: 200, damping: 10, delay: index * 0.1 + 0.2 }}
        className="text-5xl font-bold text-white mb-2 leading-tight"
      >
        {value}
      </motion.div>
      <div className="text-sm text-gray-300 uppercase tracking-wide">{label}</div>
    </motion.div>
  );
};

const Stats = () => {
  const stats = [
    { value: '100+', label: 'Hours of energy analysis saved per building.' },
    { value: '66%', label: 'of carbon emissions in NYC come from buildings.' },
    { value: '50k+', label: 'Buildings in NYC are subject to Local Law 97.' },
    { value: '10yrs', label: 'experience automating compliance processes.' },
  ];

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const controls = useAnimation();

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  };

  return (
    <motion.section
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="text-white py-24"
    >
      <div className="container mx-auto px-4 max-w-6xl">
        <motion.div variants={itemVariants} className="text-center mb-16">
          <motion.div
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="text-[#7c3aed] text-lg font-semibold mb-3 uppercase tracking-wide"
          >
            Overview
          </motion.div>
          <motion.h2
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-5xl font-bold mb-6 font-poppins leading-tight"
          >
            Why Cosmos Exists
          </motion.h2>
          <motion.p
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="text-xl max-w-2xl mx-auto mb-8 text-gray-300 leading-relaxed"
          >
            Cosmos empowers building managers to track emissions, uncover reduction opportunities, 
            and forecast potential penalties.
          </motion.p>

          <motion.p
            variants={itemVariants}
            className="text-xl mb-16 max-w-3xl mx-auto font-medium leading-relaxed text-gray-300 text-left"
          >
            Traditional ways of staying compliant are manual, time intensive and expensive.
            <br />
            It can take 40-60 hours and cost $5,000-$10,000 per building annually to hire an energy consultant to review utility usage and generate an emissions report.
            <br />
            <br />
            With Cosmos, benchmarking your emissions is automatic. Upload your utility bills and Cosmos will do the rest.
            <br />
            <br />
            Using sophisticated machine learning and OCR, Cosmos will read and analyze your utility bills to measure your building's utility usage and emissions.
          </motion.p>
        </motion.div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          {stats.map((stat, index) => (
            <StatItem key={index} {...stat} index={index} />
          ))}
        </div>
        <motion.div
          variants={itemVariants}
          className="text-center flex justify-center"
        >
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-[#7c3aed] text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-[#6d28d9] transition-colors duration-300 shadow-lg hover:shadow-xl w-60"
          >
            <Link to="/company">
              Learn about us
            </Link>
          </motion.div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Stats;